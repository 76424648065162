import {Trans} from '../../../../i18n/trans';
import {AvatarPlaceholderIcon} from './avatar-placeholder-icon';
import {
  ImageUploader,
  ImageUploaderProps,
} from '../../../../uploads/image-uploader';

interface AvatarManagerProps extends ImageUploaderProps {}
export function AvatarManager({
  title = <Trans message="Profile image" />,
  defaultImage = (
    <AvatarPlaceholderIcon
      viewBox="0 0 48 48"
      className="w-full h-full text-primary/40 bg-primary-light/40 rounded-full"
    />
  ),
  ...props
}: AvatarManagerProps) {
  return <ImageUploader title={title} defaultImage={defaultImage} {...props} />;
}
